<div class="annotate-component-container">
  <div class="tool-img-container">
    <div class="tool-container section-header"  *ngIf="imageLoaded">
      <div class="image-header" *ngIf="image !== undefined && image.name !== undefined">
        <h2>{{image.name}}</h2>
      </div>
      <span class="spacer"></span>
      <div class="tools">
        <span class="download" *ngIf="canDownload && image.image">
          <a mat-icon-button color="accent" [href]="image.image"><mat-icon>download</mat-icon></a>
        </span>
        <span class="tool">
          <button mat-icon-button color="accent" (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
        </span>
        <span class="tool">
          <button mat-icon-button color="accent" (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
        </span>
        <span class="tool" *ngIf="allowAnnotation" [ngClass]="{'tool-mode-active': panModeActive || !allowAnnotation, 'dark': isDark}">
          <button mat-icon-button color="accent" (click)="togglePanMode()"><mat-icon>open_with</mat-icon></button>
        </span>
        <span class="tool" *ngIf="allowAnnotation" [ngClass]="{'tool-mode-active': boxModeActive && allowAnnotation, 'dark': isDark}">
          <button mat-icon-button color="accent" (click)="toggleBoxMode()"><mat-icon>highlight_alt</mat-icon></button>
        </span>
        <span class="tool">
          <button mat-icon-button color="accent" (click)="restoreCanvas()"><mat-icon>restart_alt</mat-icon></button>
        </span>
      </div>
    </div>
    <div class="loading-block" *ngIf="image === undefined">
      <mat-progress-spinner mode="indeterminate" [diameter]="50" [strokeWidth]="2"></mat-progress-spinner>
      <span class="loading-message">
        Loading Image...
      </span>
    </div>

    <div class="annotate-container" *ngIf="image !== undefined">
      <canvas id="canvas" #canvasElement (wheel)="mouseZoom($event)"
              (pointerdown)="startEvent($event)"
              (pointermove)="continueEvent($event)"
              (pointerup)="finishEvent($event)">

      </canvas>
      <div style="display:none;">
        <img id="source" #imageElement
             [src]="src" loading="lazy" >
      </div>
      <annotate-dialog *ngIf="allowAnnotation"></annotate-dialog>
    </div>
  </div>
  <div class="annotate-information" *ngIf="sidebar && (showAnnotations || showMetaData)">
    <mat-tab-group color="accent" [fitInkBarToContent]="true" [selectedIndex]="tab">
      <mat-tab label="Annotations" *ngIf="showAnnotations">
        <div class="annotation-container">
          <annotation-list *ngIf="image !== undefined"
                           [image]="image"
                           (annotationConfirmed)="confirmAnnotation($event)"
                           (updateAnnotation)="updateAnnotation($event)"
                           (highlightAnnotation)="highlightAnnotation($event)"
                           (removeHighlights)="removeHighlights()"
                           (annotationRemoved)="removeAnnotation($event)"
          ></annotation-list>

          <div class="button-row">
            <button mat-stroked-button (click)="toggleBoxes()">Toggle Bounding Boxes </button>
          </div>

        </div>

      </mat-tab>
      <mat-tab label="Information" *ngIf="showMetaData" >
        <global-image-information [image]="image" [encounter]="encounter"></global-image-information>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>

<app-area-loading [criteria]="!saving" [title]="'Saving...'"></app-area-loading>


