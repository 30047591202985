<div class="content-container" *ngIf="population">
  <div class="content-header">
    <h2 matTooltip="Shown on the population 'about' page">Population Banner</h2>
    <button mat-stroked-button color="accent" (click)="updateBanner()">Update Banner</button>
  </div>
  <div class="banner-container">
    <div class="banner" *ngIf="population.banner">
      <div class="about-banner">
        <div class="banner-img">
          <img [src]="imageService.maybePrefix(population.banner)" loading="lazy" [alt]="population.displayName"/>
        </div>
      </div>

    </div>
  </div>
  <div class="banner" *ngIf="!population.banner">
    <h3>No Banner Selected!</h3>
  </div>
</div>
